import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import BugReport from "@material-ui/icons/BugReport";
import Domain from "@material-ui/icons/Domain";
import FeaturedPlayList from "@material-ui/icons/FeaturedPlayList";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Our Mission</h2>
            <h5 className={classes.description}>
              Our mission is to help organizations to filter "bad" Internet content.
                <br />
              Organizations define what is "bad" for them by themselves, whereas Categle helps to categorize web sites, domain names and individual Internet pages.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Domains Categorization"
                description="We categorize web domains for you, so that you could filter your traffic on the domain level."
                icon={Domain}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Web Pages Categorization"
                description="We categorize web pages for you, to let you filter individual web pages."
                icon={FeaturedPlayList}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="URL or IP Lookup"
                description="Threat and reputation analyzes by URL or IP. This feature is still in Alpha."
                icon={BugReport}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
