import React from "react";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

import ProductSection from "./ProductSection.jsx";

import SEO from 'react-seo-component'

class IndexPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    const siteTitle = "Web sites categorization service";
    const description = "Categorize web sites, domains and web pages in the Internet. We help organizations to filter bad Internet content."
    const siteName = "Categle"    
    return (  
      <div>
        <SEO 
            title={siteTitle} 
            description={description} 
            titleTemplate={siteName} 
            author="Categle.com" />        
        <Header
          brand="Categle.com"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/bg4.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                  <h1 className={classes.title}>Website categorization API</h1>
                  <h3 className={classes.subtitle}>
                    We protect you from the dark side of the Internet
                  </h3>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <ProductSection />
          {/* <SectionMission />
          <SectionHowItWorks /> */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(componentsStyle)(IndexPage);